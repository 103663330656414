const en = {
  ADMIN_PAGE: 'Admin Page',
  SIGN_IN_CONTINUE: 'Sign in to Continue',
  EMAIL: 'Email',
  WRONG_EMAIL: 'Wrong Email',
  EMAIL_REQUIRED: 'Email is Required',
  MIN_PASS: 'The password must be atleast 8 characters',
  PASSWORD_REQUIRED: 'Password is Required',
  PHONE_REQUIRED: 'Phone Number is Required',
  PHONE_NUMBER: 'Phone Number',
  ENTER_EMAIL: 'Enter Email',
  ENTER_PASSWORD: 'Enter Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  SIGN_IN: 'Sign In',
  EMAIL_SEND_1: 'Add email address and we will send you',
  EMAIL_SEND_2: 'insructions to reset your password.',
  SUBMIT: 'Submit',
  CORRECT_OTP: 'Please Enter Correct OTP',
  OTP_VERIFICATION: 'OTP Verification',
  ADD_VERIFICATION_CODE: 'Add verification code sent to your registered email.',
  VERIFICATION_CODE: 'Verification Code',
  VERIFY: 'Verify',
  ESTABLISHED_YEAR: 'Established Year',
  INFO: 'Info',
  RESET_PASSWORD: 'Reset Password',
  NEW_PASS_MIN: 'New Password must be atleast 8 characters',
  CONFIRM_PASS_MIN: 'Confirm Password must be 8 characters',
  NEW_PASS_REQUIRED: 'New Password is Required',
  CONFIRM_PASS_REQUIRED: 'Confirm Password is Required',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  JOB_DELETED: 'Project Deleted Successfully',
  DASHBOARD: 'Dashboard',
  AUCTION_MANAGEMENT: 'Projects',
  AUCTION_DETAIL: 'Auction Detail',
  ORDER_ID: 'Order ID:',
  ALLOW_NO_OF_COMP: 'Allows numbers company to apply on lead/contract:',
  DELETE_AUCTION: 'Delete Auction',
  EDIT_AUCTION: 'Edit Auction',
  AUCTION_ACCEPTED: 'Auction Accepted Companies',
  BID_AMOUNT: 'Bid Amount',
  JOB_DESCRIPTION: 'Project Description',
  TIMER: 'Timer',
  NAME: 'Name',
  AMOUNT: 'Amount',
  AUCTION_POSTED: 'Auction Posted By',
  LOCATION: 'Location',
  POSTED_AT: 'Posted At',
  POSTED_DATE_TIME: 'Posted Date & Time',
  POSTED_DATE: 'Posted Date',
  JOB_LOCATION: 'Project Location',
  WORK_SCOPE: 'Work Scope',
  WORK_SCOPE_REQUIRED: 'Work Scope is Required',
  BUDGET_REQUIRED: 'Budget is Required',
  STATUS: 'Status',
  ACTION: 'Action',
  SEARCH_HERE: 'Search Here',
  RECORD_NOT_FOUND: 'Record Not Found',
  EDIT_AUCTION_DETAIL: 'Edit Auction Detail',
  F_NAME_REQUIRED: 'First Name is Required',
  L_NAME_REQUIRED: 'Last Name is Required',
  NO_OF_COMPANY_REQUIRED: 'Number of Companies is Required',
  JOB_UPDATED: 'Project Updated Successfully',
  BASIC_DETAILS: 'Basic Details',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONTACT_NUMBER: 'Contact Number',
  UPDATE: 'Update',
  ADD: 'Add',
  SERVICE: 'Service',
  SUBCATEGORY: 'Subcategory',
  SERVICE_DELETED: 'Service Deleted',
  SUBCATEGORY_DELETED: 'Subcategory Deleted',
  SUBCATEGORIES: 'Subcategories',
  PROJECT_DETAIL: 'Project Detail',
  PROJECT_NAME: 'Project Name',
  WORK_DONE: 'Work that has to be done',
  PLEASE_SELECT: 'Please Select',
  BUDGET: 'Budget',
  DETAIL_DESCRIPTION: 'Detail Description',
  ADDRESS: 'Address',
  UNIT: 'Unit',
  AREA: 'Area',
  CITY: 'City',
  STATE: 'State',
  ZIPCODE: 'Zipcode',
  UPDATE_COMPANY: 'Update Company',
  COMPANY_REQUIRED: 'Company Name is Required',
  EXPERIENCE_REQUIRED: 'Experience is Required',
  EXPERIENCE: 'Experience',
  COMPANY_UPDATED: 'Company Updated Successfully',
  COMPANY_NAME: 'Company Name',
  COMPANY_DELETED: 'Company Deleted Successfully',
  COMPANY_MANAGEMENT: 'Companies',
  COMPANY_DETAIL: 'Company Detail',
  DELETE: 'Delete',
  EDIT: 'Edit',
  TOTAL_JOBS: 'Total Projects',
  JOBS: 'Projects',
  RATINGS: 'Ratings',
  NO_RATINGS_FOUND: 'No rating found',
  CONTACT_DETAIL: 'Contact Details',
  MEMBERSHIP: 'Membership',
  MEMBERSHIP_MANAGEMENT: 'Membership Management',
  PER_MONTH: 'Per Month',
  MEMBERSHIP_END_DATE: 'Membership End Date',
  PORTFOLIO: 'Portfolio',
  COMPLETED_AUCTION: 'Completed Auctions',
  COMPLETED_AUCTION_DETAIL: 'Completed Auction Detail',
  NO_RECORD_FOUND: 'No Record Found',
  NO_NOTIIFCATION_FOUND: 'No Notification Found',
  AUC_ACCEPTED: 'Auction Accepted',
  CUSTOMER: 'Homeowner',
  VIEW_DETAIL: 'View Detail',
  BID_DETAIL: 'Bid Detail',
  ADDRESS_UPDATED: 'Address Updated Successfully',
  ADDRESS_REQUIRED: 'Address is Required',
  UNIT_REQUIRED: 'Unit is Required',
  AREA_REQUIRED: 'Area is Required',
  CITY_REQUIRED: 'City is Required',
  STATE_REQUIRED: 'State is Required',
  COUNTRY_REQUIRED: 'Country is Required',
  ZIPCODE_REQUIRED: 'Zipcode is Required',
  SET_JOB_REQUEST: 'Set Project Request',
  MILES: 'Miles',
  UPDATE_ADDRESS: 'Update Address',
  CUSTOMER_UPDATED: 'Homeowner Updated Successfully',
  CUSTOMER_MANAGEMENT: 'Homeowners',
  CUSTOMER_DETAIL: 'Homeowner Detail',
  PENDING_JOBS: 'Pending Projects',
  ONGOING_AUCTIONS: 'Ongoing Auctions',
  TOTAL_BIDS: 'Total Bids',
  COMPANY: 'Company',
  COMPLETED_JOBS: 'Completed Projects',
  RATE_REVIEW: 'Rate & Review',
  JOB_POSTED: 'Project Posted',
  CUSTOMER_DELETED: 'Homeowner Deleted',
  RECORDS_PER_PAGE: 'Records Per Page',
  PENDING_AUCTIONS: 'Pending Auctions',
  PENDING_AUCTION_DETAIL: 'Pending Auction Detail',
  PENDING_JOB_DETAIL: 'Pending Project Detail',
  STATUS_UPDATED: 'Status Updated Successfully',
  COMPANY_LIST: 'Company List',
  SERVICE_REQUIRED: 'Service Name is Required',
  SERVICE_UPDATED: 'Service Updated',
  SERVICE_ADDED: 'Service Added Successfully',
  SERVICE_NAME: 'Service Name',
  SERVICE_ICON: 'Service Icon',
  SUBCATEGORY_REQUIRED: 'Subcategory Name is Required',
  SUBCATEGORY_UPDATED: 'Subcategory Updated',
  SUBCATEGORY_ADDED: 'Subcategory Added Successfully',
  SUBCATEGORY_NAME: 'Subcategory Name',
  SR_NUMBER: 'SR. Number',
  SERVICE_MANAGEMENT: 'Categories',
  MORE_PHOTOS: 'More Photos',
  OVERVIEW: 'Overview',
  POWERED_BY: 'Powered By',
  SURE_DELETE: 'Are you sure you want to delete',
  NO: 'No',
  YES: 'Yes',
  COUNTRY: 'COUNTRY',
  CONTINENT: 'CONTINENT',
  CURRENCY: 'CURRENCY',
  CURRENCY_NAME: 'CURRENCY NAME',
  CURRENCY_DESCRIPTION: 'CURRENCY DESCRIPTION',
  CURRENCY_SYMBOL: 'CURRENCY SYMBOL',
  ADMIN: 'ADMIN',
  ADMIN_REQUIRED: 'ADMIN REQUIRED',
  COUNTRY_DELETED: 'COUNTRY DELETED',
  COUNTRY_MANAGEMENT: 'Country management',
  COUNTRY_NAME: 'COUNTRY NAME',
  ADMINS_MANAGEMENT: 'ADMINS MANAGEMENT',
  AVATAR: 'AVATAR',
  OTHER_INFO: 'Other info',
  ABOUT: 'About',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  COMPANY_WAITING_FOR_YOUR_DECISION: 'Company waiting for your decision',
  COMPANY_STATUS: 'Company status',
  STREET: 'Street',
  NUMBER_OF_EMPLOYEES: 'Number of employees',
  LICENSE_NUMBER: 'License number',
  ADDRESS_DETAIL: 'Address detail',
  REASON_REQUIRED: 'Reason is required',
  COMPANY_REJECTED: 'Company was rejected',
  COMPANY_ACCEPTED: 'Company was accepted',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
  COMPANY_BLOCKED: 'Company blocked',
  COMPANY_UNBLOCKED: 'Company unblocked',
  BUDGET_GRADE: 'Budget grade',
  START: 'Start',
  AUCTION_STARTED: 'Auction started',
  NOT_PROVIDED: 'Not provided',
  REGISTERED_DATE: 'Registered date',
  MAX_BID_AMOUNT: 'Max bid amount',
  JOB_STATUS: 'Project status',
  TIME_REMAINING: 'Time remaining',
  BID_COUNT: 'Bid count',
  JOB_TITLE: 'Project title',
  POSTED_BY: 'Posted by',
  AVAILABLE_FOR_BIDS: 'Available for bids',
  FULL_NAME: 'Full name',
  BID_LIST: 'Bid list',
  STRIPE_PI: 'Stipe Payment',
  CANCEL: 'Cancel',
  RESTART: 'Restart',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  TOTAL_PAID: 'Total paid',
  AUCTIONS_WIN: 'Auctions win',
  NUMBER_OF_COMPANIES_ALLOWED: 'Number of companies allowed',
  BID_TIME: 'Bid time',
  AUCTION_END_TIME: 'Auction end time',
  JOB_ID: 'Project Id',
  MARKETER_MANAGEMENT: 'Marketers',
  WITHDRAWAL_MANAGEMENT: 'Withdrawals',
  PAYMENT_INFO: 'Payment info',
  MARKETER_BALANCE: 'Marketer Balance',
  WITHDRAWAL_DETAIL: 'Withdrawal detail',
  EARNED_TOTAL: 'Earned total',
  WITHDRAWAL_ACCEPTED: 'Withdrawal marked as paid',
  WITHDRAWAL_REJECTED: 'Withdrawal canceled',
  UPDATE_MARKETER: 'Update marketer',
  BALANCE: 'Balance',
  MARKETER_UPDATED: 'Marketer was updated',
  MARKETER_DELETED: 'Marketer was deleted',
};

export default en;
